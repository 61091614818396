import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';

export default function BenefitModal(props) {
    const [show, setShow] = useState(false);
    const [showImages, setShowImages] = useState(false);
    const [showText, setshowText] = useState(1);
    const handleClose = () => setShow(false);
    useEffect(() => {
        setShow(props.showModal)
        setShowImages(props.showImages)
        setshowText(props.showText)
    }, [props]);

    let openPaymentModal = () => {
        props.openCheckoutModal(true)
    }
    return (

        < div >
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="benefit-modal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <div className="benefit-modal-content">
                    <div className="benefit-modal-inner">
                        <div className="benefit-modal-header">
                            <span className="closeModal" onClick={handleClose}><img src={require("./../../assets/images/close.png")} alt="modal-close" /></span>
                        </div>
                        <div className="benefit-modal-body">

                            {showImages ?
                                <div className="benefit-modal-gallery">
                                    <img src={require("./../../assets/images/benefit.png")} />
                                    <img src={require("./../../assets/images/benefit-2.png")} />
                                    <img src={require("./../../assets/images/benefit-3.png")} />
                                    <img src={require("./../../assets/images/collage-6.png")} />
                                </div> : null}
                            <div className="benefit-modal-info">
                                <div className="benefit-modal-title">
                                    {showText == 2 ? <h3>Boutique Membership Benefits</h3> : showText == 3 ?
                                        <h3>Clubs Membership Benefits</h3> : showText > 3 || showText == 1 ?
                                            <h3>Girls Revenue Streams</h3> : null}
                                </div>
                                {showText == 2 ? <div className="benefit-modal-topics">
                                    <p>Sell your curated products in your own store</p>
                                    <p>Access to thousands of dancers and porn stars around the world</p>
                                    <p>No other site like this is this world</p>
                                    <p>Create your own store profile. your store fans can follow, like and share your items</p>
                                    <p> Get top dollar for your products</p>
                                    <p> Automatic New Clients daily</p>
                                    <p>Never loose costumer base</p>
                                </div>
                                    : showText == 3 ? <div className="benefit-modal-topics">
                                        <p> Clubs & Dancers</p>
                                        <p> Hire Dancers</p>
                                        <p> Traffic to your club</p>
                                        <p> Save on advertising</p>
                                        <p>Sell Subscriptions</p>
                                        <p> Access to dancers from around the world</p>
                                        <p> Dancer Check-Ins</p>
                                        <p>Promote Events</p>
                                        <p> Pre Entry</p>
                                        <p>Map Locations</p>
                                        <p>Book tables and vips</p>
                                        <p> Specials</p>
                                        <p> Keeping in contact with costumer base</p>
                                        <p>Featured Club Advertising</p>
                                        <p>Advertise in multiple cities to build customer base</p>
                                    </div>
                                        : showText > 3 || showText == 1 ? <div className="benefit-modal-topics">
                                            <p> Sell Videos</p>
                                            <p> Following, Follow</p>
                                            <p> Send Alerts to followers</p>
                                            <p> Party Booking </p>
                                            <p> Club Booking</p>
                                            <p> Subscription Video monthly/DM</p>
                                            <p> Message</p>
                                            <p> Call x/minute and Text (Paid)</p>
                                            <p> Sell personalized items</p>
                                            <p> Get Tipped</p>
                                            <p> Live Dancing</p>
                                            <p> Private Dancing</p>
                                            <p> Virtual Clubs</p>
                                            <p> Save on Bar Fees </p>
                                            <p> Never loose your customer base</p>
                                            <p>Check into clubs </p>
                                            <p> Featured Entertainer Promotion</p>
                                            <p> Access to Worldwide Costumer Base</p>
                                            <p> Never have your account deleted unlike other sites </p>
                                            <p> Stay connected to your costumer base  Whether it's through paid text or call, or webcam</p>
                                        </div> : null}
                            </div>
                            <div className="benefit-modal-action text-center">
                                {showImages ?
                                    <button className="btn theme-y" type="button" onClick={openPaymentModal}>Subscribe now</button> :

                                    <button className="btn theme-y" type="button" onClick={openPaymentModal}>Pay now</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    );
}