import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import { country } from './country'
import { withRouter } from 'react-router-dom';
import { constants } from "../../_constants/constant";
import { userService } from "../../_services"
import connect from "react-redux/es/connect/connect";

const option = country.map((item) => {
    return (
        <option value={item.name}>{item.name}</option>
    )
})
class CheckoutModal extends React.Component {
    // export default function CheckoutModal(props) {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            title: "",
            planeId: "",
            discription: "",
            city: "",
            street: "",
            state: "",
            zip: "",
            country: "",
            amount: "",
            nameOnCard: "",
            email: "",
            cardNumber: "",
            expDate: "",
            cvv: "",
            errors: [],
            term: false,
            responseError: '',
            responce: "",
            errorResponce: "",
            mm: "",
            yyyy: ""
        }
    }

    handleClose = () => this.setState({
        show: false, title: "",
        planeId: "",
        discription: "",
        city: "",
        street: "",
        state: "",
        zip: "",
        country: "",
        amount: "",
        nameOnCard: "",
        email: "",
        cardNumber: "",
        expDate: "",
        cvv: "",
        errors: [],
        mm: "",
        yyyy: "",
        term: false,
    });

    componentDidMount() {
        this.setState({
            planeId: this.props.planDetails.planeId,
            show: this.props.checkoutModal,
            title: this.props.planDetails.title,
            discription: this.props.planDetails.discription,
            amount: this.props.planDetails.price
        })

    }

    componentWillReceiveProps(nextProps) {
        if (this.state.show != nextProps.checkoutModal)
            this.setState({ show: nextProps.checkoutModal })

        this.setState({
            planeId: nextProps.planDetails.planeId,
            title: nextProps.planDetails.title,
            discription: nextProps.planDetails.discription,
            amount: nextProps.planDetails.price
        })
    }

    //card Validation
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { city, street, state, zip, country, nameOnCard, email, cardNumber, mm, yyyy, cvv, term } = this.state;
        if (!city || city.trim() == "") {
            formIsValid = false;
            errors["city"] = constants.Errors.cityError;
        }
        if (!street || street.trim() == "") {
            formIsValid = false;
            errors["street"] = constants.Errors.streetError;;
        }
        if (!state || state.trim() == "") {
            formIsValid = false;
            errors["state"] = constants.Errors.stateError;
        }
        if (!zip) {
            formIsValid = false;
            errors["zip"] = constants.Errors.zipError;
        }
        if (zip) {
            // if (!zip.trim().match("^[0-9]{5}(?:-[0-9]{4})?$")) {
            //     formIsValid = false;
            //     errors["zip"] = "please enter valid zip/postal code.";
            // }
            if (!zip.trim().match(/^[a-zA-Z0-9]{0,8}$/)) {
                formIsValid = false;
                errors["zip"] = "please enter valid zip/postal code.";
            }
        }

        if (!country) {
            formIsValid = false;
            errors["country"] = constants.Errors.countryError;
        }
        if (!nameOnCard || nameOnCard.trim() == "") {
            formIsValid = false;
            errors["nameOnCard"] = constants.Errors.nameOnCardError;;
        }
        if (!email) {
            formIsValid = false;
            errors["email"] = constants.Errors.BlankEmailError;;
        }
        if (email) {
            if (!email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                formIsValid = false;
                errors["email"] = constants.Errors.EmailFormatError;
            }
        }

        if (!cardNumber) {
            formIsValid = false;
            errors["cardNumber"] = constants.Errors.cardNumberError;;
        }
        if (cardNumber)
            if (!cardNumber.trim().match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|62[0-9]{14})$/)) {
                formIsValid = false;
                errors["cardNumber"] = "please enter valid card number.";
            }
        if (!mm) {
            formIsValid = false;
            errors["mm"] = constants.Errors.mmError;;
        }
        if (mm)
            if (!mm.trim().match("^(0?[1-9]|1[012])$")) {
                formIsValid = false;
                errors["mm"] = "please enter valid month.";
            }

        if (!yyyy) {
            formIsValid = false;
            errors["yyyy"] = constants.Errors.yyyyError;;
        }
        if (yyyy)
            if (!yyyy.trim().match(/(?:(?:20|30)[0-9]{2})/)) {
                formIsValid = false;
                errors["yyyy"] = "please enter valid year.";
            }

        if (!cvv) {
            formIsValid = false;
            errors["cvv"] = constants.Errors.cvvError;;
        }
        if (cvv)
            if (!cvv.trim().match(/^[0-9]{3,4}$/)) {
                formIsValid = false;
                errors["cvv"] = "please enter valid cvv.";
            }

        if (!term) {
            formIsValid = false;
            errors["term"] = "please check term & Conditions.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    //set states on ValueChange
    handleChange = e => {
        let errors = this.state.errors;
        if (e.target.value) {
            errors[e.target.name] = ''

        }
        if (e.target.name == "mm" || e.target.name == "cvv" || e.target.name == "yyyy" || e.target.name == "cardNumber") {
            errors["errorResponce"] = ''
        }
        this.setState({
            [e.target.name]: e.target.value,
            errors: errors,
            responseError: '',
        })

    }

    //payment Action Call
    handleSubmit = () => {

        let { } = this.state;
        const { history } = this.props;
        const { planeId, title, discription, city, street, state, zip, country, nameOnCard, email, amount, cardNumber, mm, yyyy, cvv, errors } = this.state;
        if (this.handleValidation()) {
            let data = {
                planDetails: {
                    planeId: planeId,
                    title: title,
                    price: amount,
                    discription: discription
                },
                paymentDetails: {
                    city: city,
                    street: street,
                    state: state,
                    zip: zip,
                    country: country

                },
                cardDetails: {
                    nameOnCard: nameOnCard,
                    email: email,
                    amount: amount,
                    cardNumber: cardNumber,
                    expDate: yyyy + "-" + mm,
                    cvv: cvv
                }
            }
            // this.setState({ loader: true });
            userService.cardPayment(data).then(response => {
                if (response.data && response.data.statusCode == 1) {
                    if (response.data.responseData && response.data.responseData.result && response.data.responseData.result[0].code == "1") {
                        this.setState({
                            responce: "Your membership has been activated successfully!"
                        })
                    }
                    else if (response.data.responseData && response.data.responseData.result && response.data.responseData.result[0].description) {
                        this.setState({
                            responce: response.data.responseData.result[0].description
                        })
                    }

                }
                if (response.data.error && response.data.error.errors.transactionResponse.errors && response.data.error.errors.transactionResponse.errors[0].errorText) {
                    errors["errorResponce"] = response.data.error.errors.transactionResponse.errors[0].errorText
                    this.setState({ errors: errors })
                }

            }).catch(e => {
                this.setState({ loader: false, responseError: "Something went wrong" })
            })
        }
    }


    openCheckoutModal = () => { this.props.openCheckoutModal(true) }
    GoToAccount = () => this.props.history.push({ pathname: "/profile" })
    render() {
        let { show, nameOnCard, email, city, street, state, zip, country, amount, cardNumber, cardName, mm, yyyy, cvv, errors, term, responce, } = this.state
        return (
            <div>
                <Modal
                    show={show}
                    onHide={() => this.handleClose()}
                    dialogClassName="benefit-modal"
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <div className="benefit-modal-content">
                        {responce == "" ?
                            <div className="benefit-modal-inner">
                                <div className="benefit-modal-header">
                                    <span className="closeModal" onClick={this.handleClose}><img src={require("./../../assets/images/close.png")} alt="modal-close" /></span>
                                </div>
                                <div className="benefit-modal-body">
                                    <div className="benefit-modal-info">
                                        <div className="payment-title">
                                            <h3>Select Payment Option</h3>
                                        </div>
                                        <div className="payment-form-col-wrap">
                                            <div className="payment-form-col left">
                                                <div className="payment-form-col-title">
                                                    <p>Payment details</p>
                                                    <span>We are fully compliant with payment card industry data security standard.</span>
                                                </div>
                                                <div className="payment-input-wrap">
                                                    <input type="text" className="form-control" placeholder="Street" name="street" value={street} onChange={this.handleChange} />
                                                    {
                                                        errors["street"] && <span className="custom-alert">
                                                            {errors["street"]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="payment-input-wrap">
                                                    <input type="text" className="form-control" placeholder="City" name="city" value={city} onChange={this.handleChange} />
                                                    {
                                                        errors["city"] && <span className="custom-alert">
                                                            {errors["city"]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="payment-input-wrap">
                                                    <input type="text" className="form-control" placeholder="State / Province" name="state" value={state} onChange={this.handleChange} />
                                                    {
                                                        errors["state"] && <span className="custom-alert">
                                                            {errors["state"]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="payment-input-wrap">
                                                    <input type="text" className="form-control" placeholder="Zip / Postal Code" name="zip" value={zip} onChange={this.handleChange} />
                                                    {
                                                        errors["zip"] && <span className="custom-alert">
                                                            {errors["zip"]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="payment-input-wrap">
                                                    <select className="form-control" name="country" value={country} onChange={this.handleChange} >
                                                        <option value="">Select Country</option>
                                                        {
                                                            option
                                                        }
                                                    </select>
                                                    {
                                                        errors["country"] && <span className="custom-alert">
                                                            {errors["country"]}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="payment-form-col right">
                                                <div className="payment-form-col-title">
                                                    <p>Card details</p>
                                                </div>
                                                <div className="payment-input-wrap">
                                                    <input type="text" className="form-control" placeholder="E-mail" name="email" value={email} onChange={this.handleChange} />
                                                    {
                                                        errors["email"] && <span className="custom-alert">
                                                            {errors["email"]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="payment-input-wrap">
                                                    <input type="text" className="form-control" placeholder="Name On Card" name="nameOnCard" value={nameOnCard} onChange={this.handleChange} />
                                                    {
                                                        errors["nameOnCard"] && <span className="custom-alert">
                                                            {errors["nameOnCard"]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="payment-input-wrap">
                                                    <input type="text" className="form-control" placeholder="Card Number" name="cardNumber" value={cardNumber} onChange={this.handleChange} />
                                                    {
                                                        errors["cardNumber"] && <span className="custom-alert">
                                                            {errors["cardNumber"]}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="payment-input-wrap cardinfo">
                                                    <div class="card-exp-date-input">
                                                        <input type="text" className="form-control" placeholder="MM" name="mm" value={mm} onChange={this.handleChange} />
                                                        <input type="text" className="form-control" placeholder="YYYY" name="yyyy" value={yyyy} onChange={this.handleChange} />
                                                        <input type="text" className="form-control" placeholder="CVV" name="cvv" value={cvv} onChange={this.handleChange} />

                                                    </div>
                                                    <div class="card-exp-date-error">
                                                        {
                                                            errors["mm"] && <span className="custom-alert">
                                                                {errors["mm"]}

                                                            </span>
                                                        }
                                                        {
                                                            errors["yyyy"] && <span className="custom-alert">
                                                                {errors["yyyy"]}
                                                            </span>
                                                        }
                                                        {
                                                            errors["cvv"] && <span className="custom-alert">
                                                                {errors["cvv"]}
                                                            </span>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="payment-input-wrap">
                                                    <a href="/terms" target="self">Terms & Conditions</a>
                                                    <label for="term">
                                                        <input type="radio" name="term" id="term" onChange={this.handleChange} checked={term} />
                                                        <span></span> Tick here to confirm that you are at least 18 year old and the age of majority in your Place of residence.
                                            </label>
                                                    {
                                                        errors["term"] && <span className="custom-alert" style={{
                                                            position: "absolute",
                                                            marginTop: " 45px"
                                                        }}>
                                                            {errors["term"]}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="benefit-modal-action text-center">
                                        {
                                            errors["errorResponce"] && <span className="custom-alert" style={{
                                                position: "static",
                                                fontSize: "16px"
                                            }}>
                                                {errors["errorResponce"]}
                                            </span>
                                        }
                                        <button className="btn theme-y" type="button" onClick={this.handleSubmit}>Submit</button>
                                        <div style={{ paddingTop: "20px" }}>
                                            <h6 style={{ color: "white", fontFamily: "initial", fontSize: "16px" }}>The funds raised form this offer will be used to develop TBSWW App. The award levels offered on this site are for future promotion on the TBSWW App. The TBSWW App will launch in March 2021.</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="benefit-modal-inner">
                                <div className="action-result-msg-wrap">
                                    <div className="action-result-msg">
                                        <h2 style={{ color: "#5ff465" }}>{responce}</h2>
                                    </div>
                                    <div className="benefit-modal-action text-center">
                                        <button className="btn theme-y" type="button" onClick={this.GoToAccount}>Go To Account</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
            </div >
        );
    }
}
const mapStateToProps = state => {
    return {};
};
export default withRouter(connect(mapStateToProps, null)(CheckoutModal));