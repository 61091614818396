
import React from "react"
import LoginPage from "./login";
import { withRouter } from 'react-router-dom';
import SignupPage from "./signup";
import ForgetPassword from "./forgetPassword";
import connect from "react-redux/es/connect/connect";
import ResetPassword from "./resetPassword";


class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formType: "logIn"
        }
    }

    componentDidMount() {

        if (localStorage.getItem('accessToken') && this.props.location.pathname.split('/', 2)[1] != "reset_password") {

            // this.props.history.push({ pathname: "/plans" })
        }
        if (this.props.location && this.props.location.pathname == "/signup") {
            this.changeForm("signUp");
        }
        else if (this.props.location && this.props.location.pathname == "/login") {
            this.changeForm("logIn");
        }
        else if (this.props.location && this.props.location.pathname == "/forget_password") {
            this.changeForm("forget_password");
        }
        else if (this.props.location && this.props.location.pathname.split('/', 2)[1] == "reset_password") {
            this.changeForm("reset_password");
        }

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.user != nextProps.user) {
            if (nextProps.user && nextProps.user.userInfo.isSubscribed > 0) {
                this.props.history.push({ pathname: "/profile" })
            }
            else if (nextProps.user && nextProps.user.userInfo.isSubscribed == 0) {
                this.props.history.push({ pathname: "/plans" })
            }
        }
    }
    // function for change form inside home page (signup,logIn,forget_password,reset_password,plans )
    changeForm = (setformType) => {
        this.setState({ formType: setformType })
    }

    render() {
        // if (localStorage.getItem('accessToken')) { return null }
        let { formType } = this.state
        return (<div className="authwrapper prelogin">
            <span className="corner-art left">
                <img src={require("./../../assets/images/left-bottom-shade.png")} alt="logo" />
            </span>
            <span className="corner-art right">
                <img src={require("./../../assets/images/right-top-shade.png")} alt="logo" />
            </span>
            <div className="auth-container">
                <div className="authHeader">
                    <a className="authlogo" href="javascript:void(0);">
                        <img src={require("./../../assets/images/logo.png")} alt="logo" />
                    </a>
                </div>

                <div className="auth-col-wrapper clearfix">
                    <div className="auth-col left">
                        <div className="auth-collage">
                            <img className="collage collage-1" src={require("./../../assets/images/collage-1.png")} />
                            <img className="collage collage-2" src={require("./../../assets/images/collage-2.png")} />
                            <img className="collage collage-3" src={require("./../../assets/images/collage-3.png")} />
                            <img className="collage collage-4" src={require("./../../assets/images/collage-4.png")} />
                            <img className="collage collage-5" src={require("./../../assets/images/collage-5.png")} />
                            <img className="collage collage-6" src={require("./../../assets/images/collage-6.png")} />
                        </div>
                    </div>

                    <div className="auth-col right">
                        {formType == "logIn" ?
                            <LoginPage changeForm={this.changeForm} />
                            : formType == "signUp" ?
                                <SignupPage changeForm={this.changeForm} /> :
                                formType == "forget_password" ?
                                    <ForgetPassword />
                                    : formType == "reset_password" ?
                                        <ResetPassword /> :
                                        null}
                    </div>

                </div>
                <div>
                    <h6 style={{ color: "white", fontFamily: "initial", fontSize: "16px" }}>The funds raised form this offer will be used to develop TBSWW App. The award levels offered on this site are for future promotion on the TBSWW App. The TBSWW App will launch in March 2021.</h6>
                </div>
            </div>

        </div>

        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.user,
    }
};
export default withRouter(connect(mapStateToProps, null)(AuthPage));