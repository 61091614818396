import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const loggerMiddleware = createLogger();

const composeEnhancers = composeWithDevTools(
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
  );

export const store = createStore(
    rootReducer,
    composeEnhancers
);