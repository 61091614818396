import React from "react"
import { withRouter } from 'react-router-dom';
import { constants } from "../../_constants/constant";
import { userService } from "../../_services"
import { logout } from "../../_actions/user.actions";
import connect from "react-redux/es/connect/connect";
class ChangeEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // loader: false,
            currentEmail: '',
            password: '',
            email: '',
            errors: [],
        }
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                currentEmail: this.props.location.state.email
            })
        }
    }

    //Validation
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { currentEmail, password, email } = this.state;

        if (!password) {
            formIsValid = false;
            errors["password"] = constants.Errors.BlankPasswordError;
        }
        if (!email) {
            formIsValid = false;
            errors["email"] = constants.Errors.BlankEmailError;
        }
        if (email) {
            if (!email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                formIsValid = false;
                errors["email"] = constants.Errors.EmailFormatError;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    //set states on ValueChange
    handleChange = e => {
        let errors = this.state.errors;

        if (e.target.value) {
            errors[e.target.name] = ''
        }
        this.setState({
            [e.target.name]: e.target.value,
            errors: errors,
            responseError: '',
        })

    }

    //change-email Submit Action Call
    handleSubmit = () => {

        const { currentEmail, password, email } = this.state;
        if (this.handleValidation()) {
            let obj = {
                "email": email.trim(),
                // 'email': userName_or_email.trim(),                
                'password': password
            }
            // this.setState({ loader: true })
            userService.changeEmail(obj)
                .then(response => {
                    if (response.data.statusCode == 1 && response.data.responseData.userProfile) {
                        let errors = {}
                        errors["responce"] = response.data.responseData.message;
                        this.setState({ errors: errors })
                        setTimeout(() => {
                            this.props.history.push({ pathname: "/profile" })
                        }, 1200);

                    }
                    else {
                        if (response.data.error) {
                            let errors = {}
                            if (response.data.error && response.data.error.errorCode == 6) {
                                errors["email"] = response.data.error.responseMessage;
                            }
                            if (response.data.error && response.data.error.errorCode == 11) {
                                errors["password"] = response.data.error.responseMessage;
                            }

                            this.setState({ errors: errors })
                        }
                    }
                }).catch(e => {
                    this.setState({
                        loader: false,
                        responseError: constants.Errors.DefaultError
                    })
                })
        }
    }

    logout = () => {
        this.props.logout().then((res) => {
            this.props.history.push({ pathname: "/login" })
        })

    }

    // function for redirect to forget password page 
    forgetPassword = () => {
        this.props.logout().then((res) => {
            this.props.history.push({ pathname: "/forget_password" })
        })
    }
    // function for back to profile page using cancel button
    cancel = () => {
        this.props.history.goBack()
    }

    // function for redirect to profile page 
    profile = () => {
        this.props.history.push({ pathname: "/profile" })
    }
    render() {
        const { currentEmail, password, email, errors } = this.state;
        return (
            <div className="authwrapper">
                <div className="auth-container">
                    <div className="main-header">
                        <div className="main-logo-wrap">
                            <a className="authlogo" href="javascript:void(0);">
                                <img src={require("./../../assets/images/logo.png")} alt="logo" />
                            </a>
                        </div>
                        <div className="main-header-content">
                            <span className="main-title">
                                BSWW Pre-launch Specials
                    </span>
                            <span className="contact-info">
                                <span className="isd-code">219</span>
                                713-8698
                    </span>
                        </div>
                        <div className="main-header-nav">
                            <a className="header-nav-btn" onClick={this.logout}>
                                <img title="logout" src={require("./../../assets/images/app-ico.png")} alt="logo" />
                            </a>
                            <a className="header-nav-btn" onClick={this.profile}>
                                <img title="profile" src={require("./../../assets/images/profile-ico.png")} alt="logo" />
                            </a>
                            {/* <a href="/" className="header-nav-btn">
                                <img title="search" src={require("./../../assets/images/search-ico.png")} alt="logo" />
                            </a> */}
                        </div>
                    </div>
                    <div className="profilePageWrap forChangeEmail">
                        <div className="profilePageContent">
                            <div className="profilePageTitleWrap">
                                <h3>Change Email</h3>
                            </div>
                            <div className="profilePageFormWrap">
                                <div className="form-group forinput">
                                    <input type="email" className="form-control" placeholder="Current Email" value={currentEmail} disabled />
                                </div>
                                <div className="form-group forinput">
                                    <input type="email" className="form-control" placeholder="New Email" name="email" value={email} onChange={this.handleChange} />
                                    {
                                        errors["email"] && <span className="custom-alert">
                                            {errors["email"]}
                                        </span>
                                    }
                                </div>
                                <div className="form-group forinput">
                                    <input type="password" className="form-control" placeholder="Current Password" name="password" value={password} onChange={this.handleChange} />
                                    <div className="forgetPasswordNavWrap">
                                        <span onClick={this.forgetPassword}>Forget Password?</span>
                                    </div>
                                    {/* {
                                        errors["password"] && <span className="custom-alert">
                                            {errors["password"]}
                                        </span>
                                    } */}
                                </div>

                                <div className="authSubActionWrap">
                                    {
                                        errors["responce"] && <p className="  custom-alert-success">
                                            {errors["responce"]}
                                        </p>
                                    }</div>
                                <div className="form-group btn-wrap form-btns">
                                    <button type="button" className="btn theme-b" onClick={this.handleSubmit}>Save</button>
                                    <button type="button" className="btn theme-b" onClick={this.cancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};
export default withRouter(connect(mapStateToProps, { logout })(ChangeEmail));