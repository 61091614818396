import axios from 'axios';
import API from "../config/API";

export const userService = {
    login,
    signUp,
    logout,
    getUser,
    forgotpassword,
    resetPassword,
    changePassword,
    changeEmail,
    cardPayment,
    getUserSubscribedPlan

};

function login(params) {
    return axios.post(API.LOGIN, params)
        .then((res) => {
            res.statusCode = 1;
            return res
        })

        .catch((error) => {
            error.statusCode = 0;
            return error
        })
}

function signUp(params) {
    return axios.post(API.REGISTER, params)
        .then((res) => {
            res.statusCode = 1;
            return res
        })
        .catch((error) => {
            error.statusCode = 0;
            return error
        })
}


function logout() {
    let accessToken;
    accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    return axios.post(API.LOGOUT)
}

function forgotpassword(params) {
    return axios.post(API.FORGOT_PASSWORD, params)
}

function resetPassword(params) {
    let accessToken;
    accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    return axios.post(API.RESET_PASSWORD, params)
}
function changePassword(params) {
    let accessToken;
    accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    return axios.post(API.CHANGE_PASSWORD, params)
}
function changeEmail(params) {
    let accessToken;
    accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    return axios.post(API.CHANGE_EMAIL, params)
}

function getUser(params) {
    return axios.get(API.GETUSER, params)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            console.log("err : ", err)
            return err;
        })
}
function getUserSubscribedPlan() {
    let accessToken;
    accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    return axios.get(API.USERS_SUBSCRIBED_PLAN)
}

function cardPayment(params) {
    let accessToken;
    accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common['accessToken'] = `${accessToken}`;
    return axios.post(API.CARD_PAYMENT, params)
}
