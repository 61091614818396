import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';

export default function PaymentModal(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        setShow(props.paymentModal)

    }, [props]);
    let openCheckoutModal = () => { props.openCheckoutModal(true) }
    return (
        <div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="benefit-modal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <div className="benefit-modal-content">
                    <div className="benefit-modal-inner">
                        <div className="benefit-modal-header">
                            <span className="closeModal" onClick={handleClose}><img src={require("./../../assets/images/close.png")} alt="modal-close" /></span>
                        </div>
                        <div className="benefit-modal-body">
                            <div className="benefit-modal-info">
                                <div className="payment-title">
                                    <h3>Select Payment Option</h3>
                                </div>
                                <div className="payment-opt-wrap">
                                    <label for="paymentOpt1">
                                        <input type="radio" className="payment-radio" name="paymentOpt" id="paymentOpt1" checked="true" />
                                        <span></span> BY CREDIT CARD
                                        </label>
                                    {/* <label for="paymentOpt2">
                                        <input type="radio" className="payment-radio" name="paymentOpt" id="paymentOpt2" />
                                        <span></span> BY DEBIT CARD
                                        </label>
                                    <label for="paymentOpt3">
                                        <input type="radio" className="payment-radio" name="paymentOpt" id="paymentOpt3" />
                                        <span></span> BY INTERNERT BANKING
                                        </label>
                                    <label for="paymentOpt4">
                                        <input type="radio" className="payment-radio" name="paymentOpt" id="paymentOpt4" />
                                        <span></span> BY PAYPAL
                                        </label>
                                    <label for="paymentOpt5">
                                        <input type="radio" className="payment-radio" name="paymentOpt" id="paymentOpt5" />
                                        <span></span> BY VENMO
                                        </label> */}
                                </div>
                            </div>
                            <div className="benefit-modal-action text-center">

                                <button className="btn theme-y" type="button" onClick={openCheckoutModal}>Pay now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}