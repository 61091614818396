
import React from "react"
import { withRouter } from 'react-router-dom';
import { constants } from "../../_constants/constant";
import connect from "react-redux/es/connect/connect";
import { login } from "../../_actions/user.actions";
class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // loader: false,
            userName_or_email: '',
            password: '',
            errors: [],
        }
    }
    componentDidMount() {
    }

    //Validation
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { userName_or_email, password } = this.state;

        if (!userName_or_email) {
            formIsValid = false;
            errors["userName_or_email"] = constants.Errors.BlankEmailError;
        }
        if (!password) {
            formIsValid = false;
            errors["password"] = constants.Errors.BlankPasswordError;
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    //set states on ValueChange
    handleChange = e => {
        let errors = this.state.errors;

        if (e.target.value) {
            errors[e.target.name] = ''
        }
        this.setState({
            [e.target.name]: e.target.value,
            errors: errors,
            responseError: '',
        })

    }



    //LOGINSubmitActionCall
    handleSubmit = () => {
        let { userName_or_email, password } = this.state;

        if (this.handleValidation()) {
            let obj = {
                'email': userName_or_email.trim(),
                'password': password
            }
            // this.setState({ loader: true })
            this.props.login(obj)
                .then(response => {
                    if (response.data.statusCode == 1) {
                        if (response.data.responseData && response.data.responseData.userProfile.isSubscribed > 0) {
                            this.props.history.push({ pathname: "/profile" })
                        }
                        else if (response.data.responseData && response.data.responseData.userProfile.isSubscribed == 0) {
                            this.props.history.push({ pathname: "/plans" })
                        }
                    } else {
                        if (response.data.error && response.data.error.errorCode == 9) {
                            let errors = {}
                            errors["userName_or_email"] = response.data.error.responseMessage;
                            this.setState({ errors: errors })
                        }
                        if (response.data.error && response.data.error.errorCode == 10) {
                            let errors = {}
                            errors["password"] = response.data.error.responseMessage;
                            this.setState({ errors: errors })
                        }
                    }
                }).catch(e => {
                    this.setState({
                        loader: false,
                        responseError: constants.Errors.DefaultError
                    })
                })
        }
    }

    render() {
        let { userName_or_email, password, errors } = this.state


        return (
            <div className="authLoginBoxWrap loginbox">
                <div className="authLoginBoxInner">
                    <div className="authTitle">
                        <h3>Login</h3>
                    </div>
                    <div className="authFormWrap">
                        <div className="authInputWrap">
                            <input className="form-control" type="text" placeholder="Username/Email" name="userName_or_email" value={userName_or_email} onChange={this.handleChange} />
                            {
                                errors["userName_or_email"] && <span className="custom-alert">
                                    {errors["userName_or_email"]}
                                </span>
                            }
                        </div>
                        <div className="authInputWrap">
                            <input className="form-control" type="password" placeholder="Password" name="password" value={password} onChange={this.handleChange} />
                            {
                                errors["password"] && <span className="custom-alert">
                                    {errors["password"]}
                                </span>
                            }
                        </div>
                        <div className="authActionWrap">
                            <div className="authSubmitWrap">
                                <button type="submit" onClick={this.handleSubmit} className="btn btn-theme">Login</button>
                            </div>
                            <div className="authSubActionWrap">
                                <p>Don't have an account yet ?</p>
                                <p >Signup for <a href="/signup">BaddestStrippersWorldWide.com</a></p>
                                <p><a href="forget_password">Forgot Password ?</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {};
};
export default withRouter(connect(mapStateToProps, { login })(LoginPage));