
import React from "react"

class My404Component extends React.Component {

    render() {

        return (
            <div className="authwrapper">

                <div className="auth-container">
                    <div className="main-header">
                        <div className="main-logo-wrap">
                            <a className="authlogo" href="javascript:void(0);">
                                <img src={require("./../../assets/images/logo.png")} alt="logo" />
                            </a>
                        </div>
                        <div className="main-header-content">
                            <span className="main-title">
                                BSWW Pre-launch Specials
                        </span>
                            <span className="contact-info">
                                <span className="isd-code">219</span>
                                713-8698
                        </span>
                        </div>
                        <div className="main-header-nav">
                            <a className="header-nav-btn" onClick={this.logout}>
                                <img title="logout" src={require("./../../assets/images/app-ico.png")} alt="logo" />
                            </a>
                            {/* <a href="" className="header-nav-btn">
                                <img title="profile" src={require("./../../assets/images/profile-ico.png")} alt="logo" />
                            </a> */}
                            {/* <a href="/" className="header-nav-btn">
                                <img src={require("./../../assets/images/search-ico.png")} alt="logo" />
                            </a> */}
                        </div>
                    </div>

                    <div className="profilePageWrap">

                        <div className="profilePageTitleWrap">
                            <h3>404! page not found.</h3>
                        </div>                    </div>

                </div>

            </div>
        )
    }
}


export default My404Component