import { userConstants } from '../_constants';
import { userService } from '../_services'
import promise from 'bluebird';
import { ResponseFilter } from "../config/response-handler";

export const userActions = {
    login,
    signUp,
    logout,
    getUser
};
export function signUp(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            userService.signUp(params)
                .then(response => {
                    if (response.data.responseData && response.data.responseData.accessToken)
                        localStorage.setItem('accessToken', response.data.responseData.accessToken);

                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };
    function request(userSignupRequest) { return { type: userConstants.LOGIN_REQUEST, userSignupRequest } }
    function success(userSignupSuccess) { return { type: userConstants.LOGIN_SUCCESS, userSignupSuccess } }
    function failure(userSignupFail) { return { type: userConstants.LOGIN_ERROR, userSignupFail } }
}


export function login(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            userService.login(params)
                .then(response => {
                    if (response.data.responseData && response.data.responseData.accessToken)
                        localStorage.setItem('accessToken', response.data.responseData.accessToken);

                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(userLoginRequest) { return { type: userConstants.LOGIN_REQUEST, userLoginRequest } }
    function success(userLoginSuccess) { return { type: userConstants.LOGIN_SUCCESS, userLoginSuccess } }
    function failure(userLoginFail) { return { type: userConstants.LOGIN_ERROR, userLoginFail } }
}

export function getUser(params) {
    return function (dispatch) {

        return new promise(function (resolve, reject) {

            dispatch(request(params));
            userService.getUser(params)
                .then(response => {
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                    if (err.errorCode == 2) {
                        localStorage.clear();
                        this.props.history.push('/')
                    }
                })
        })
    };
    function request(getUserRequest) { return { type: userConstants.GETUSER_REQUEST, getUserRequest } }
    function success(getUserSuccess) { return { type: userConstants.GETUSER_SUCCESS, getUserSuccess } }
    function failure(getUserFail) { return { type: userConstants.GETUSER_ERROR, getUserFail } }
}


export function logout() {
    return function (dispatch) {

        return new promise(function (resolve, reject) {
            dispatch(request());
            userService.logout()
                .then(response => {
                    localStorage.clear()
                    resolve(response);
                    let { serverResponseData } = ResponseFilter(response);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));

                })
        })
    };
    function request(userLogoutRequest) { return { type: userConstants.LOGOUT_REQUEST, userLogoutRequest } }
    function success(userLogoutSuccess) { return { type: userConstants.LOGOUT_SUCCESS, userLogoutSuccess } }
    function failure(userLogoutFail) { return { type: userConstants.LOGOUT_ERROR, userLogoutFail } }
}
