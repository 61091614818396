
import React from "react"
import { withRouter } from 'react-router-dom';
import { constants } from "../../_constants/constant";
import { userService } from "../../_services"
import { logout } from "../../_actions/user.actions";
import connect from "react-redux/es/connect/connect";
import LogoutModal from "../common/logout";
class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // plan1Img: "./../../assets/images/screen3-2.png",
            // plan2Img: "./../../assets/images/screen3-3.png",
            // plan3Img: "./../../assets/images/screen3-1.png",
            // plan4Img: "./../../assets/images/screen3-4.png",
            // plan5Img: "./../../assets/images/screen3-5.png",
            logoutPopup: false,
            name: "",
            location: "",
            email: "",
            title: "",
            price: "",
            discription: ""
        }
    }


    componentDidMount() {
        this.getUserSubscribedPlan()
    }

    getUserSubscribedPlan = () => {
        this.setState({ email: " " })
        userService.getUserSubscribedPlan().then((res) => {

            if (res.data.responseData && res.data.responseData.length > 0) {
                this.setState({
                    discription: res.data.responseData[0].planDetails.discription,
                    price: res.data.responseData[0].planDetails.price,
                    title: res.data.responseData[0].planDetails.title,
                    name: res.data.responseData[0].userInfo[0].name,
                    email: res.data.responseData[0].userInfo[0].email,
                    location: res.data.responseData[0].paymentDetails.state + "," + res.data.responseData[0].paymentDetails.country
                }
                )
            }

        })
    }

    // function for redirect to change password page
    changePassword = () => {
        this.props.history.push({ pathname: "/change_password" })
    }
    // function for redirect to change email page
    changeEmail = () => {
        this.props.history.push({ pathname: "/change_email", state: { email: this.state.email } })
    }

    logout = () => {
        this.props.logout().then((res) => {
            this.props.history.push({ pathname: "/login" })
        })
    }
    logoutPopup = () => {
        this.setState({ logoutPopup: true })
    }
    rendertoPlan = () => {
        this.props.history.push({ pathname: "/plans" })
    }

    render() {
        let { location, email, name, title, price, discription } = this.state

        return (
            <div className="authwrapper">
                <LogoutModal showModal={this.state.logoutPopup} logout={this.logout}></LogoutModal>
                <div className="auth-container">
                    <div className="main-header">
                        <div className="main-logo-wrap">
                            <a className="authlogo" href="javascript:void(0);">
                                <img src={require("./../../assets/images/logo.png")} alt="logo" />
                            </a>
                        </div>
                        <div className="main-header-content">
                            <span className="main-title">
                                BSWW Pre-launch Specials
                        </span>
                            <span className="contact-info">
                                <span className="isd-code">219</span>
                                713-8698
                        </span>
                        </div>
                        <div className="main-header-nav">
                            <a className="header-nav-btn" style={{ cursor: "pointer", color: "white", borderBottom: "2px solid #fff", borderTop: "2px solid #fff" }} onClick={this.logoutPopup}>
                                {/* <img title="logout" src={require("./../../assets/images/app-ico.png")} alt="logo" /> */}
                                &nbsp; Logout
                            </a>
                            {/* <a href="" className="header-nav-btn">
                                <img title="profile" src={require("./../../assets/images/profile-ico.png")} alt="logo" />
                            </a> */}
                            {/* <a href="/" className="header-nav-btn">
                                <img src={require("./../../assets/images/search-ico.png")} alt="logo" />
                            </a> */}
                        </div>
                    </div>

                    <div className="profilePageWrap">
                        {email != "" ?
                            <>
                                <div className="profilePageContent">

                                    <div className="profilePageTitleWrap">
                                        <h3>ACCOUNT</h3>

                                    </div>

                                </div>
                                <div className="profileInfoColWrap">
                                    <div className="profileInfoCol left">
                                        <div className="profileSectionTitle">
                                            <h3>Membership & Billing</h3>
                                        </div>
                                        <div className="profileBasicInfoWrap">
                                            {/* <div className="profilePicWrap">
                                        <img src={require("./../../assets/images/userprofilepic.jpg")} alt="user profile picture" />
                                        <input type="file" id="changePic" name="changePic" />
                                        <div class="profilePicActionWrap">
                                            <label for="changePic">Edit</label>
                                        </div>
                                    </div> */}
                                            <div className="profileNameWrap">
                                                <span className="userFullName">{name}</span>
                                                <span className="userLocation">{location}</span>
                                            </div>
                                        </div>
                                        <div className="profileMoreInfoWrap">
                                            <div className="profileMoreInfoCol">
                                                <span className="profileMoreInfoVal">{email}</span>
                                                <span className="profileMoreInfoAction" onClick={this.changeEmail}>Change Account Email</span>
                                            </div>
                                            <div className="profileMoreInfoCol">
                                                <span className="profileMoreInfoVal">Password : **********</span>
                                                <span className="profileMoreInfoAction" onClick={this.changePassword}>Change Password</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="profileInfoCol right">
                                        <div className="profileSectionTitle">
                                            <h3>Your Membership Details</h3>
                                        </div>
                                        <div className="membershipDetailsContainer">
                                            <div className="membershipDetailsWrap">
                                                <div className="membershipDetailsPic">
                                                    {
                                                        price == 100 ?
                                                            <img src={require("./../../assets/images/screen3-2.jpg")} alt="user profile picture" /> :
                                                            title == "BOUTIQUES" ?
                                                                <img src={require("./../../assets/images/screen3-3.jpg")} alt="user profile picture" /> :
                                                                price == 300 ?
                                                                    <img src={require("./../../assets/images/screen3-1.jpg")} alt="user profile picture" /> :
                                                                    price == 1000 ?
                                                                        <img src={require("./../../assets/images/screen3-4.jpg")} alt="user profile picture" /> :
                                                                        price == 500 ?
                                                                            <img src={require("./../../assets/images/screen3-5.jpg")} alt="user profile picture" /> : null
                                                    }

                                                    {/* <img src={require("./../../assets/images/userprofilepic.jpg")} alt="user profile picture" /> */}
                                                </div>
                                                <div className="membershipDetailsInfoWrap">
                                                    <div className="membershipTitle">
                                                        <span>{title}</span>
                                                    </div>
                                                    <div className="membershipPrice">
                                                        {price !== '' &&
                                                            <span>${price}</span>
                                                        }
                                                    </div>
                                                    <div className="membershipDescription">
                                                        <span>{discription}</span>
                                                    </div>
                                                    {/* <div className="membershipDetailsAction text-center">
                                                <button type="button" className="btn theme-y">MORE DETAILS</button>
                                            </div> */}
                                                </div>

                                            </div>
                                            {price === '' &&
                                                <div class="noSubsBlock" onClick={this.rendertoPlan}>
                                                    <span className="txt" >Add Membership Now</span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <div className="profilePageTitleWrap">
                                <h3>No Subscription data found</h3>
                            </div>}
                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {};
};
export default withRouter(connect(mapStateToProps, { logout })(Profile))