import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
    RedditShareButton,
    RedditIcon,
    EmailShareButton,
    EmailIcon,
} from 'react-share';

export default function ShareWithModal(props) {
    const [show, setShow] = useState(false);
    // const [showImages, setShowImages] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        setShow(props.showModal)
    }, [props]);
    function myFunction() {
        var copyText = document.getElementById("myInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
        // alert("Copied the text: " + copyText.value);
    }
    return (
        <div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="benefit-modal"
                style={{ width: "300px!important" }}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <div className="benefit-modal-content">
                    <div className="benefit-modal-inner">
                        <div className="benefit-modal-header">
                            <span className="closeModal" onClick={handleClose}><img src={require("./../../assets/images/close.png")} alt="modal-close" /></span>
                        </div>
                        <div className="benefit-modal-body">

                            <div className="benefit-modal-info">
                                <div className="benefit-modal-title">
                                    <h3>Share With</h3>
                                </div>
                                <div className=" text-center" style={{ color: "#fafafa", margin: "35px" }}>
                                    {/* <FacebookShareButton url={"ddddddd"}><FacebookIcon></FacebookIcon></FacebookShareButton> */}
                                    <WhatsappShareButton url={window.location.href} style={{ margin: "10px" }}><WhatsappIcon size={32} round={true}></WhatsappIcon></WhatsappShareButton>
                                    <TwitterShareButton url={window.location.href} style={{ margin: "10px" }}>< TwitterIcon size={32} round={true}></TwitterIcon></TwitterShareButton>
                                    <LinkedinShareButton url={window.location.href} style={{ margin: "10px" }}> <LinkedinIcon size={32} round={true}></LinkedinIcon></LinkedinShareButton>
                                    <TelegramShareButton url={window.location.href} style={{ margin: "10px" }}><TelegramIcon size={32} round={true}></TelegramIcon></TelegramShareButton>
                                    <RedditShareButton url={window.location.href} style={{ margin: "10px" }}><RedditIcon size={32} round={true}></RedditIcon></RedditShareButton>
                                    <EmailShareButton url={window.location.href} style={{ margin: "10px" }}><EmailIcon size={32} round={true}></EmailIcon></EmailShareButton>

                                </div>

                                <div className=" text-center" style={{ color: "#fafafa", margin: "35px" }}>
                                    <input id="myInput" type="text" value={window.location.href} ></input> <button className="btn theme-y" type="button" style={{ margin: "20px", minWidth: "130px", color: "#fafafa" }} onClick={myFunction}>Copy URL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}