import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-elastic-carousel';
export default function PlanDetail(props) {
    const [show, setShow] = useState(false);
    const [amount, setImage] = useState("");

    const handleClose = () => setShow(false);
    useEffect(() => {
        setShow(props.planDetailModal)
        setImage(props.planDetails.price)
    }, [props]);
    const bp = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 3, itemsToScroll: 2 },
        { width: 850, itemsToShow: 4 },
        { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 4 },
        { width: 1750, itemsToShow: 4 },
    ]
    let carousel = undefined;

    let openPlanBenefitModal = () => { props.openPlanBenefitModal(true) }
    return (
        <div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="plan-detail-modal plan1080"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <div className="benefit-modal-content">
                    <div className="benefit-modal-inner">
                        <div className="plan-detail-modal-header">
                            <div className="plan-detail-title">
                                <h3>Entertainer ${amount}</h3>
                            </div>
                            <span className="closeModal" onClick={handleClose}><img src={require("./../../assets/images/close.png")} alt="modal-close" /></span>
                        </div>
                        <div className="benefit-modal-body">
                            <div className="benefit-modal-gallery">
                                <Carousel ref={ref => (carousel = ref)} focusOnSelect={false} breakPoints={bp}>
                                    <div className="plan-carousal-item plan-red">
                                        <div className="plan-carousal-actress-pic-wrap">
                                            <div className="plan-carousal-actress-pic-label">
                                                <span>SPONSORED & TOP PAGE ADVERTISEING</span>
                                            </div>
                                            <div className="plan-carousal-actress-pic">
                                                <img src={require("./../../assets/images/actor-1.jpg")} alt="actress" />
                                            </div>
                                            <div className="plan-carousal-actress-pic-social-action">
                                                <a href="#" className="actress-social-share-btn">
                                                    <span className="s-ico">
                                                        <img src={require("./../../assets/images/follow-ico.png")} alt="follow" />
                                                    </span>
                                                    <span className="s-txt">
                                                        Follow
                                                    </span>
                                                </a>
                                                <a href="#" className="actress-social-share-btn">
                                                    <span className="s-ico">
                                                        <img src={require("./../../assets/images/share-ico.png")} alt="follow" />
                                                    </span>
                                                    <span className="s-txt">
                                                        Share
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="plan-carousal-actress-basic-info">
                                                <div className="actress-basic-info-left">
                                                    <span className="actress-name">DAZIA MORGAN</span>
                                                    <span className="actress-live-status"></span>
                                                    <span className="actress-username">@daziamorgan</span>
                                                </div>
                                                <div className="actress-basic-info-right">
                                                    <span className="actress-more-action-wrap">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plan-carousal-actress-opt-wrap">
                                            <span>TEXT ME</span>
                                            <span>CALL ME</span>
                                            <span>TIP ME</span>
                                            <span>MY STORE</span>
                                            <span>BOOK ME</span>
                                            <span>PRIVATE ROOM</span>
                                        </div>
                                        <div className="plan-carousal-actress-subscription">
                                            <button type="button" className="btn theme-y">SUBSCRIBE FOR  $10.99 (PER MONTH)</button>
                                        </div>
                                    </div>
                                    <div className="plan-carousal-item">
                                        <div className="plan-carousal-actress-pic-wrap">
                                            <div className="plan-carousal-actress-pic-label"></div>
                                            <div className="plan-carousal-actress-pic">
                                                <img src={require("./../../assets/images/actor-2.jpg")} alt="actress" />
                                            </div>
                                            <div className="plan-carousal-actress-pic-social-action">
                                                <a href="#" className="actress-social-share-btn">
                                                    <span className="s-ico">
                                                        <img src={require("./../../assets/images/follow-ico.png")} alt="follow" />
                                                    </span>
                                                    <span className="s-txt">
                                                        Follow
                                                    </span>
                                                </a>
                                                <a href="#" className="actress-social-share-btn">
                                                    <span className="s-ico">
                                                        <img src={require("./../../assets/images/share-ico.png")} alt="follow" />
                                                    </span>
                                                    <span className="s-txt">
                                                        Share
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="plan-carousal-actress-basic-info">
                                                <div className="actress-basic-info-left">
                                                    <span className="actress-name">GYPSY MERMAID</span>
                                                    <span className="actress-live-status"></span>
                                                    <span className="actress-username">@daziamorgan</span>
                                                </div>
                                                <div className="actress-basic-info-right">
                                                    <span className="actress-more-action-wrap">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plan-carousal-actress-opt-wrap">
                                            <span>BOOK ME</span>
                                            <span>TEXT ME</span>
                                            <span>MY STORE</span>
                                            <span>TIP ME</span>
                                            <span>CALL ME</span>
                                            <span>PRIVATE ROOM</span>
                                        </div>
                                        <div className="plan-carousal-actress-subscription">
                                            <button type="button" className="btn theme-y">SUBSCRIBE FOR  $10.99 (PER MONTH)</button>
                                        </div>
                                    </div>
                                    <div className="plan-carousal-item">
                                        <div className="plan-carousal-actress-pic-wrap">
                                            <div className="plan-carousal-actress-pic-label"></div>
                                            <div className="plan-carousal-actress-pic">
                                                <img src={require("./../../assets/images/actor-3.jpg")} alt="actress" />
                                            </div>
                                            <div className="plan-carousal-actress-pic-social-action">
                                                <a href="#" className="actress-social-share-btn">
                                                    <span className="s-ico">
                                                        <img src={require("./../../assets/images/follow-ico.png")} alt="follow" />
                                                    </span>
                                                    <span className="s-txt">
                                                        Follow
                                                    </span>
                                                </a>
                                                <a href="#" className="actress-social-share-btn">
                                                    <span className="s-ico">
                                                        <img src={require("./../../assets/images/share-ico.png")} alt="follow" />
                                                    </span>
                                                    <span className="s-txt">
                                                        Share
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="plan-carousal-actress-basic-info">
                                                <div className="actress-basic-info-left">
                                                    <span className="actress-name">STAR DIVINE</span>
                                                    <span className="actress-live-status"></span>
                                                    <span className="actress-username">@daziamorgan</span>
                                                </div>
                                                <div className="actress-basic-info-right">
                                                    <span className="actress-more-action-wrap">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plan-carousal-actress-opt-wrap">
                                            <span>BOOK ME</span>
                                            <span>TEXT ME</span>
                                            <span>MY STORE</span>
                                            <span>TIP ME</span>
                                            <span>CALL ME</span>
                                            <span>PRIVATE ROOM</span>
                                        </div>
                                        <div className="plan-carousal-actress-subscription">
                                            <button type="button" className="btn theme-y">SUBSCRIBE FOR  $10.99 (PER MONTH)</button>
                                        </div>
                                    </div>
                                    <div className="plan-carousal-item">
                                        <div className="plan-carousal-actress-pic-wrap">
                                            <div className="plan-carousal-actress-pic-label"></div>
                                            <div className="plan-carousal-actress-pic">
                                                <img src={require("./../../assets/images/actor-4.jpg")} alt="actress" />
                                            </div>
                                            <div className="plan-carousal-actress-pic-social-action">
                                                <a href="#" className="actress-social-share-btn">
                                                    <span className="s-ico">
                                                        <img src={require("./../../assets/images/follow-ico.png")} alt="follow" />
                                                    </span>
                                                    <span className="s-txt">
                                                        Follow
                                                    </span>
                                                </a>
                                                <a href="#" className="actress-social-share-btn">
                                                    <span className="s-ico">
                                                        <img src={require("./../../assets/images/share-ico.png")} alt="follow" />
                                                    </span>
                                                    <span className="s-txt">
                                                        Share
                                                    </span>
                                                </a>
                                            </div>
                                            <div className="plan-carousal-actress-basic-info">
                                                <div className="actress-basic-info-left">
                                                    <span className="actress-name">DALLAS DA BODY</span>
                                                    <span className="actress-live-status"></span>
                                                    <span className="actress-username">@daziamorgan</span>
                                                </div>
                                                <div className="actress-basic-info-right">
                                                    <span className="actress-more-action-wrap">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="plan-carousal-actress-opt-wrap">
                                            <span>BOOK ME</span>
                                            <span>TEXT ME</span>
                                            <span>MY STORE</span>
                                            <span>TIP ME</span>
                                            <span>CALL ME</span>
                                            <span>PRIVATE ROOM</span>
                                        </div>
                                        <div className="plan-carousal-actress-subscription">
                                            <button type="button" className="btn theme-y">SUBSCRIBE FOR  $10.99 (PER MONTH)</button>
                                        </div>
                                    </div>
                                </Carousel>
                                <div className="plan-carousal-nav-wrap">
                                    <span className="plan-carousal-nav left" onClick={() => carousel.slidePrev()}>PREV</span>
                                    <span className="plan-carousal-nav right" onClick={() => carousel.slideNext()}>NEXT</span>
                                </div>
                            </div>
                            <div className="benefit-modal-info">
                                <div className="plan-info-title">
                                    <h3>Girls Revenue Streams:</h3>
                                </div>
                                <div className="plan-info-option">
                                    <span>Sell Video</span>
                                    <span>Following, Follow</span>
                                    <span>Send Alerts to followers</span>
                                    <span>Party Booking</span>
                                    <span>Club Booking</span>
                                    <span>Subscription Video monthly/DM</span>
                                    <span>Message</span>
                                    <span>Call x/minute</span>
                                    <span>Sell Personlized items</span>
                                    <span>Get Tipped</span>
                                    <span>Live Dancing</span>
                                    <span>Private Dancing</span>
                                    <span>Virtual Clubs</span>
                                </div>
                            </div>
                            <div className="benefit-modal-action text-center">
                                <button className="btn theme-y" type="button" onClick={openPlanBenefitModal}>Subscribe now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}