function ResponseFilter(response) {

  if (response.data.error && response.data.error.errorCode == 2) {

    localStorage.clear();
  }
  var serverResponseStatus = response.status;
  var serverResponseData = (typeof response.data != 'undefined')
    ? response.data
    : ((typeof response.error != 'undefined') ? response.error : null);

  return {
    serverResponseStatus,
    serverResponseData
  }
}

export {
  ResponseFilter,
}
