import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';

export default function MoreBenefitModal(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        setShow(props.moreBenefitModal)

    }, [props]);

    let openPlanBenefitModal = () => {
        props.openPlanBenefitModal(true)
    }
    return (
        <div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="plan-detail-modal"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <div className="benefit-modal-content">
                    <div className="benefit-modal-inner">
                        <div className="plan-detail-modal-header">
                            <div className="plan-detail-title">
                                <h3>The Only Strip Club Platform in the World</h3>
                            </div>
                            <span className="closeModal" onClick={handleClose}>
                                <img src={require("./../../assets/images/close.png")} alt="modal-close" />
                            </span>
                        </div>
                        <div className="benefit-modal-body">
                            <div className="more-benefit-col-wrap clearfix">
                                <div className="more-benefit-col-left">
                                    <div className="more-benefit-gallery-wrap">
                                        <img src={require("./../../assets/images/p7-1.png")} alt="more benefit pictures" />
                                        <img src={require("./../../assets/images/p7-2.png")} alt="more benefit pictures" />
                                    </div>
                                </div>
                                <div className="more-benefit-col-right">
                                    <div className="more-benefit-info">
                                        <div className="more-benefit-title">
                                            <h3>BENEFITS</h3>
                                        </div>
                                        <div className="more-benefit-gallery">
                                            <div className="more-benefit-gallery-item">
                                                <div className="more-benefit-gallery-item-pic">
                                                    <img src={require("./../../assets/images/p7-8.png")} alt="more benefit pictures" />
                                                </div>
                                                <div className="more-benefit-gallery-item-txt">
                                                    <span>Dancer Check-Ins</span>
                                                </div>
                                            </div>
                                            <div className="more-benefit-gallery-item">
                                                <div className="more-benefit-gallery-item-pic">
                                                    <img src={require("./../../assets/images/p7-5.png")} alt="more benefit pictures" />
                                                </div>
                                                <div className="more-benefit-gallery-item-txt">
                                                    <span>Promote Events</span>
                                                </div>
                                            </div>
                                            <div className="more-benefit-gallery-item">
                                                <div className="more-benefit-gallery-item-pic">
                                                    <img src={require("./../../assets/images/p7-3.png")} alt="more benefit pictures" />
                                                </div>
                                                <div className="more-benefit-gallery-item-txt">
                                                    <span>Pre Entry</span>
                                                </div>
                                            </div>
                                            <div className="more-benefit-gallery-item">
                                                <div className="more-benefit-gallery-item-pic">
                                                    <img src={require("./../../assets/images/p7-6.png")} alt="more benefit pictures" />
                                                </div>
                                                <div className="more-benefit-gallery-item-txt">
                                                    <span>Map Locations</span>
                                                </div>
                                            </div>
                                            <div className="more-benefit-gallery-item">
                                                <div className="more-benefit-gallery-item-pic">
                                                    <img src={require("./../../assets/images/p7-4.png")} alt="more benefit pictures" />
                                                </div>
                                                <div className="more-benefit-gallery-item-txt">
                                                    <span>Book table</span>
                                                </div>
                                            </div>
                                            <div className="more-benefit-gallery-item">
                                                <div className="more-benefit-gallery-item-pic">
                                                    <img src={require("./../../assets/images/p7-9.png")} alt="more benefit pictures" />
                                                </div>
                                                <div className="more-benefit-gallery-item-txt">
                                                    <span>Specials</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="more-benefit-info">
                                        <div className="more-benefit-title">
                                            <h3>MORE BENEFITS</h3>
                                        </div>
                                        <div className="more-benefit-labels">
                                            <span>Clubs & Dancers</span>
                                            <span>Hire Dancers</span>
                                            <span>Traffic to your club</span>
                                            <span>Save on advertising</span>
                                            <span>Sell subscriptions</span>
                                            <span>Access to dancers from around the world</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="benefit-modal-action text-center">
                                <button className="btn theme-y" type="button" onClick={(e) => openPlanBenefitModal(e)}>Subscribe now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}