
import React from "react"
import { withRouter } from 'react-router-dom';
import { constants } from "../../_constants/constant";
import { userService } from "../../_services"
class ResetPasswordPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // loader: false,
            password: '',
            confirmPassword: '',
            errors: [],
            isReset: false
        }
    }
    componentDidMount() {
        localStorage.setItem('accessToken', this.props.match.params.id)
    }

    componentWillUnmount() {
        if (!this.state.isReset) {
            localStorage.removeItem('accessToken');
        }
    }

    //Validation
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { password, confirmPassword } = this.state;

        if (!password) {
            formIsValid = false;
            errors["password"] = constants.Errors.BlankPasswordError;
        }
        else {
            //var pattern = ;
            if (!password.match(/^\S{6,}$/)) {
                formIsValid = false;
                errors["passwordPattern"] = "true";
                errors["password"] = constants.Errors.PasswordFormatError.Line_1;
            }
        }
        if (!confirmPassword) {
            formIsValid = false;
            errors["confirmPassword"] = constants.Errors.BlankPasswordError;
        }

        if (password && confirmPassword) {
            if (password !== confirmPassword) {
                formIsValid = false;
                errors["confirmPassword"] = constants.Errors.PasswordMatchError;
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    //set states on ValueChange
    handleChange = e => {
        let errors = this.state.errors;

        if (e.target.value) {
            errors[e.target.name] = ''
        }
        this.setState({
            [e.target.name]: e.target.value,
            errors: errors,
            responseError: '',
        })

    }



    //reset password Submit Action Call
    handleSubmit = () => {
        let { password } = this.state;
        if (this.handleValidation()) {
            let obj = {
                'password': password
            }
            // this.setState({ loader: true })
            userService.resetPassword(obj)
                .then(response => {
                    if (response.data.statusCode == 1 && response.data.responseData && response.data.responseData.userProfile.isSubscribed > 0) {
                        // this.props.history.push({ pathname: "/profile" })
                        let errors = {}
                        errors["responce"] = response.data.responseData.message;
                        this.setState({ errors: errors, isReset: true })
                        setTimeout(() => {
                            this.props.history.push({ pathname: "/profile" })
                        }, 1200);
                    }
                    else if (response.data.statusCode == 1 && response.data.responseData && response.data.responseData.userProfile.isSubscribed == 0) {
                        // this.props.history.push({ pathname: "/plans" })
                        let errors = {}
                        errors["responce"] = response.data.responseData.message;
                        this.setState({ errors: errors, isReset: true })
                        setTimeout(() => {
                            this.props.history.push({ pathname: "/profile" })
                        }, 1200);
                    }
                    // if (response.data.statusCode == 1 && response.data.responseData.userProfile) {
                    //     let errors = {}
                    //     errors["responce"] = response.data.responseData.message;
                    //     this.setState({ errors: errors, isReset: true })
                    //     setTimeout(() => {
                    //         this.props.history.push({ pathname: "/plans" })
                    //     }, 1200);

                    // }
                    else {
                        if (response.data.error) {
                            let errors = {}
                            errors["password"] = response.data.error.responseMessage;
                            this.setState({ errors: errors })
                        }
                    }
                }).catch(e => {
                    this.setState({
                        loader: false,
                        responseError: constants.Errors.DefaultError
                    })
                })
        }
    }

    render() {
        let { confirmPassword, password, errors } = this.state


        return (
            <div className="authLoginBoxWrap loginbox">
                <div className="authLoginBoxInner">
                    <div className="authTitle">
                        <h3>Reset Password</h3>
                    </div>
                    <div className="authFormWrap">


                        <div className="authInputWrap">
                            <input className="form-control" type="password" placeholder="Password" name="password" value={password} onChange={this.handleChange} />
                            {
                                errors["password"] && <span className="custom-alert">
                                    {errors["password"]}
                                </span>
                            }
                        </div>
                        <div className="authInputWrap">
                            <input className="form-control" type="password" placeholder="confirm password" name="confirmPassword" value={confirmPassword} onChange={this.handleChange} />
                            {
                                errors["confirmPassword"] && <span className="custom-alert">
                                    {errors["confirmPassword"]}
                                </span>
                            }
                        </div>
                        <div className="authActionWrap">
                            <div className="authSubActionWrap">
                                {
                                    errors["responce"] && <p className="  custom-alert-success">
                                        {errors["responce"]}
                                    </p>
                                }</div>
                            <div className="authSubmitWrap">
                                <button type="submit" onClick={this.handleSubmit} className="btn btn-theme">Save</button>
                            </div>
                            <div className="authSubActionWrap">
                                <p>Don't have an account yet ?</p>
                                <p >Signup for <a href="/signup">BaddestStrippersWorldWide.com</a></p>
                                {/* <p><a href="forget_password">Forgot Password ?</a></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ResetPasswordPage);