import React, { useState, useEffect } from "react"
import Modal from 'react-bootstrap/Modal';

export default function LogoutModal(props) {
    const [show, setShow] = useState(false);
    // const [showImages, setShowImages] = useState(false);
    const handleClose = () => setShow(false);
    useEffect(() => {
        setShow(props.showModal)
    }, [props]);

    return (
        <div>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="benefit-modal"
                style={{ width: "300px!important" }}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <div className="benefit-modal-content">
                    <div className="benefit-modal-inner">
                        <div className="benefit-modal-header">
                            <span className="closeModal" onClick={handleClose}><img src={require("./../../assets/images/close.png")} alt="modal-close" /></span>
                        </div>
                        <div className="benefit-modal-body">

                            <div className="benefit-modal-info">
                                <div className="benefit-modal-title">
                                    <h3>Logout</h3>
                                </div>
                                <div className=" text-center" style={{ color: "#fafafa", margin: "35px" }}>
                                    <p>Are you sure you want to Logout?</p>
                                </div>
                            </div>
                            <div className="benefit-modal-action text-center">

                                <button className="btn theme-y" type="button" style={{ margin: "20px", minWidth: "130px" }} onClick={handleClose}>Cancel</button>

                                <button className="btn theme-y" type="button" style={{ margin: "20px", minWidth: "130px" }} onClick={props.logout}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}