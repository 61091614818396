import siteSetting from "./url";
export default {
    'LOGIN': siteSetting.api.url + "/user/login",
    'REGISTER': siteSetting.api.url + "/user/signup",
    "LOGOUT": siteSetting.api.url + "/user/logout",
    "FORGOT_PASSWORD": siteSetting.api.url + "/user/forgotPassword",
    "RESET_PASSWORD": siteSetting.api.url + "/user/resetPassword",
    "CHANGE_PASSWORD": siteSetting.api.url + "/user/changePassword",
    "CHANGE_EMAIL": siteSetting.api.url + "/user/changeEmail",
    "CARD_PAYMENT": siteSetting.api.url + "/subscription/cardPayment",
    "USERS_SUBSCRIBED_PLAN": siteSetting.api.url + "/user/getUserSubscribedPlan"
}
