import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from 'history';
import { PrivateRoute, PublicRoute } from './routing/PrivateRoute'
import AuthPage from './components/auth/authpage';
import term from './components/auth/bddssTerm';
import Plans from './components/plans/plans';
import ForgetPassword from './components/auth/forgetPassword';
import Profile from './components/profile/profile';
import My404Component from './components/profile/My404Component';
import ChangePassword from './components/profile/changePassword';
import ChangeEmail from './components/profile/changeEmail';

export const history = createBrowserHistory();

class Routes extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (<Router history={history}>
      <Switch>
        <Route path="/terms" component={term} />
        {/* <PublicRoute path="/faq" component={Faq} /> */}
        {/* <PrivateRoute path="/upgrdepremium" component={UpgrdePremium} /> */}

        <PublicRoute exact path="/login" component={AuthPage} />
        <PublicRoute exact path="/signup" component={AuthPage} />
        <PublicRoute exact path="/forget_password" component={AuthPage} />
        <PublicRoute exact path="/reset_password/:id" component={AuthPage} />
        <PrivateRoute exact path="/plans" component={Plans} />

        <PublicRoute exact path="/" component={AuthPage} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/change_password" component={ChangePassword} />
        <PrivateRoute exact path="/change_email" component={ChangeEmail} />
        <Route path='*' exact={true} component={My404Component} />
        <Redirect from="*" to="/" ></Redirect>
      </Switch>
    </Router>
    )
  }
}

function mapStateToProps(state) {
}
const connectedApp = connect(mapStateToProps)(Routes);
export { connectedApp as Routes };