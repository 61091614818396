
import React from "react";
import { withRouter } from 'react-router-dom';
import { constants } from "../../_constants/constant";
import connect from "react-redux/es/connect/connect";
import { signUp } from "../../_actions/user.actions";

class SignupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // loader: false,
            email: '',
            password: '',
            name: '',
            UserName: '',
            term: false,
            errors: [],
            responseError: '',

        }
    }
    componentDidMount() {
        if (localStorage.getItem('accessToken')) {
            this.props.history.push({ pathname: "/plans" })
        }
    }

    //SIGNUPValidation
    handleSignupValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { userName, name, email, password, term } = this.state;
        if (!name || name.trim() == "") {
            formIsValid = false;
            errors["name"] = constants.Errors.nameError;
        }
        if (!userName || userName.trim() == "") {
            formIsValid = false;
            errors["userName"] = constants.Errors.usernameError;;
        }
        if (!email) {
            formIsValid = false;
            errors["email"] = constants.Errors.BlankEmailError;
        }
        if (email) {
            if (!email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                formIsValid = false;
                errors["email"] = constants.Errors.EmailFormatError;
            }
        }
        if (!password) {
            formIsValid = false;
            errors["password"] = constants.Errors.BlankPasswordError;
        }
        else {
            //var pattern = ;
            if (!password.match(/^\S{6,}$/)) {
                formIsValid = false;
                errors["passwordPattern"] = "true";
                errors["password"] = constants.Errors.PasswordFormatError.Line_1;
            }
        }
        if (!term) {
            formIsValid = false;
            errors["term"] = "please check term & Conditions.";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    //set states on ValueChange
    handleChange = e => {
        let errors = this.state.errors;

        if (e.target.value) {
            errors[e.target.name] = ''
        }
        this.setState({
            [e.target.name]: e.target.value,
            errors: errors,
            responseError: '',
        })

    }
    openTerm = () => {
        window.location.assign("bddssTerm.html")
    }

    //SIGNUP Action Call
    handleSignupSubmit = () => {

        let { email, password, userName, name } = this.state;
        const { history } = this.props;

        if (this.handleSignupValidation()) {
            let data = {
                'email': email.trim(),
                'password': password,
                'userName': userName.trim(),
                'name': name.trim(),
            }
            // this.setState({ loader: true });
            this.props.signUp(data).then(response => {
                if (response.data && response.data.statusCode == 1) {
                    this.setState({

                        responseError: constants.SUCCESS_MESG.EmailSent_mesg,
                        email: '', password: '', name: '', userName: ''
                    });
                    history.push({
                        pathname: '/plans'  //path
                    })
                } else {
                    if (response.data.error && response.data.error.errorCode == 6) {
                        let errors = {}
                        errors["email"] = response.data.error.responseMessage;
                        this.setState({ errors: errors })
                    }
                    if (response.data.error && response.data.error.errorCode == 66) {
                        let errors = {}
                        errors["userName"] = response.data.error.responseMessage;
                        this.setState({ errors: errors })
                    }
                    this.setState({
                        // loader: false,
                        password: '',
                        responseError: response.data.error ? response.data.error.errorMessage : "",
                    })
                }
            }).catch(e => {
                this.setState({ loader: false, responseError: "Something went wrong" })
            })
        }
    }

    render() {
        const { userName, name, email, password, term, errors } = this.state;
        return (
            <div className="authLoginBoxWrap signupbox">
                <div className="authLoginBoxInner">
                    <div className="authTitle">
                        <h3>Signup</h3>
                    </div>
                    <div className="authFormWrap">
                        <div className="authInputWrap">
                            <input className="form-control" type="text" placeholder="Name" name="name" value={name} onChange={this.handleChange} />
                            {
                                errors["name"] && <span className="custom-alert">
                                    {errors["name"]}
                                </span>
                            }
                        </div>
                        <div className="authInputWrap">
                            <input className="form-control" type="text" placeholder="Email" name="email" value={email} onChange={this.handleChange} />
                            {
                                errors["email"] && <span className="custom-alert">
                                    {errors["email"]}
                                </span>
                            }
                        </div>
                        <div className="authInputWrap">
                            <input className="form-control" type="text" placeholder="Username" name="userName" value={userName} onChange={this.handleChange} />
                            {
                                errors["userName"] && <span className="custom-alert">
                                    {errors["userName"]}
                                </span>
                            }
                        </div>
                        <div className="authInputWrap">
                            <input className="form-control" type="password" placeholder="Password" name="password" value={password} onChange={this.handleChange} />
                            {
                                errors["password"] && <span className="custom-alert">
                                    {errors["password"]}
                                </span>
                            }
                        </div>
                        <div className="authCheckWrap">
                            <div className="checkWrap">
                                <label for="checkForTerm">
                                    <input type="checkbox" id="checkForTerm" name="term" onChange={this.handleChange} checked={term} />
                                    <span className="checkmark"></span> Agree with <a href="/terms" target="self">Terms & Conditions.</a>

                                </label>


                            </div>
                            {
                                errors["term"] && <span className="custom-alert" style={{
                                    position: "absolute",
                                    marginTop: " 20px"
                                }}>
                                    {errors["term"]}
                                </span>
                            }
                        </div>
                        <div className="authActionWrap">
                            <div className="authSubmitWrap">
                                <button type={'Submit'}
                                    onClick={this.handleSignupSubmit} className="btn theme-blue">Signup</button>
                            </div>
                            <div className="authSubActionWrap">
                                <p >If you already have an account ? <a href="/login">Login</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};
export default withRouter(connect(mapStateToProps, { signUp })(SignupPage));