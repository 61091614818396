
import React from "react"
import { withRouter } from 'react-router-dom';
import { constants } from "../../_constants/constant";
import { userService } from "../../_services"

class ForgetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // loader: false,
            email: '',
            errors: [],
        }
    }
    componentDidMount() {

    }

    //Validation
    handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { email } = this.state;

        if (!email) {
            formIsValid = false;
            errors["email"] = constants.Errors.BlankEmailError;
        } if (email) {
            if (!email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                formIsValid = false;
                errors["email"] = constants.Errors.EmailFormatError;
            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    //set states on ValueChange
    handleChange = e => {
        let errors = this.state.errors;

        if (e.target.value) {
            errors[e.target.name] = ''
        }
        this.setState({
            [e.target.name]: e.target.value,
            errors: errors,
            responseError: '',
        })

    }



    //forgotpassword SubmitActionCall
    handleSubmit = () => {
        let { email } = this.state;
        if (this.handleValidation()) {
            let obj = {
                'email': email.trim(),
            }
            // this.setState({ loader: true })
            userService.forgotpassword(obj)
                .then(response => {
                    if (response.data.statusCode == 1) {
                        // this.props.history.push({ pathname: "/plans" })
                        let errors = {}
                        errors["responce"] = response.data.responseData.message;
                        this.setState({ errors: errors })
                    } else {
                        if (response.data.error) {
                            let errors = {}
                            errors["email"] = response.data.error.responseMessage;
                            this.setState({ errors: errors })
                        }
                    }
                }).catch(e => {
                    this.setState({
                        loader: false,
                        responseError: constants.Errors.DefaultError
                    })
                })
        }
    }

    render() {
        let { email, errors } = this.state


        return (
            <div className="authLoginBoxWrap loginbox">
                <div className="authLoginBoxInner">
                    <div className="authTitle">
                        <h3>Forgot Password</h3>
                    </div>
                    <div className="authFormWrap">
                        <div className="authInputWrap">
                            <input className="form-control" type="text" placeholder=" Registered Email" name="email" value={email} onChange={this.handleChange} />
                            {
                                errors["email"] && <span className="custom-alert">
                                    {errors["email"]}
                                </span>
                            }
                        </div>
                        <div className="authActionWrap">
                            <div className="authSubActionWrap">
                                {
                                    errors["responce"] && <p className="  custom-alert-success">
                                        {errors["responce"]}
                                    </p>
                                }</div>
                            <div className="authSubmitWrap">
                                <button type="submit" onClick={this.handleSubmit} className="btn btn-theme">send</button>
                            </div>
                            <div className="authSubActionWrap">
                                <p>Don't have an account yet ?</p>
                                <p >Signup for <a href="/signup">BaddestStrippersWorldWide.com</a></p>
                                {/* <p><a href="javascript:void(0);">Forgot Password ?</a></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ForgetPassword);