
export const KEYBOARD_KEYS = {
    ENTER: 13
};

export const constants = {

    Errors: {
        cityError: "City cannot be empty",
        streetError: "Street cannot be empty",
        stateError: "State cannot be empty",
        zipError: "Zip cannot be empty",
        countryError: "Country cannot be empty",
        amountError: "Amount cannot be empty",
        cardNumberError: "Card number cannot be empty",
        mmError: "month cannot be empty",
        yyyyError: "year cannot be empty",
        cvvError: "CVV cannot be empty",
        nameError: "Name cannot be empty",
        nameOnCardError: "Name on card cannot be empty",
        usernameError: "User Name cannot be empty",
        BlankEmailError: "Email address cannot be empty",
        EmailFormatError: "Please enter a valid email address",
        BlankPasswordError: "Password cannot be empty",
        DefaultError: "Something Went Wrong",
        BlankNewPasswordError: "New Password cannot be empty",
        BlankOldPasswordError: "Old Password cannot be empty",
        BlankConfirmPasswordError: "Confirm Password cannot be empty",
        PasswordMatchError: "Both passwords don't match",
        OldNewPasswordMatchError: "Old and New Password can't be same",
        PasswordFormatError: {
            Line_1: "Must be a minimum of 6 characters.",
            Line_2: "Require one number, one upper case letter, one lower case letter in the password.",
            Line_3: "*Suggested that use one special character (i.e: #, $, %)"
        },
    },
    SUCCESS_MESG: {
        PasswordChangeMesg: "Password Reset Successful",
        EmailSent_mesg: 'Verification email has been sent to your registered email',
        Notify_Mesg: 'Thank you for choosing to sign up with us. Stay tuned for our grand unveiling soon',
        WaitListMsg: 'We have added you the waitlist, the first 1000 exclusive signups are now full, and will keep you informed when the community is open for you.'
    },
    CHANGE_PASSWORD: {
        title: "Change Password",
        input1: "Old Password",
        input2: "New Password",
        input3: "Confirm Password",
    },
    EMAIL_INPUT: {
        Header: "Already have an account?",
        title: "You don't have any email address. So you need to enter email address manually to complete the join us proccess.",
    },
    EMAIL_TEXT: "Email address",
    PASSWORD_TEXT: "Password",
    USER: {
        EmptyNameError: "Name cannot be empty",
        NameFormatError: "Name cannot be numeric",
    },
    LINK_VALIDATION_ERROR: "Please enter a valid url",
    FEED_POST: "POST",
    FEED_QUESTION: "QUESTION",
    FEED_ANSWER: "ANSWER",
    EMAIL_REGEX: "",
    ForgetEmailSentMesaage: "Check your registered email for a password reset link.",
    DEBOUNCE_TIME: 1000,

    // static picture

    emailRegex: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
}
