import { userConstants } from '../_constants';



const initialState = {

};

export function user(state = initialState, action) {
    switch (action.type) {

        case userConstants.LOGIN_SUCCESS:
            let userInfo = action.userLoginSuccess.responseData.userProfile;
            return { ...state, userInfo };

        case userConstants.SIGNUP_SUCCESS:
            let signupInfo = action.userSignupSuccess.responseData.userProfile;
            return { ...state, signupInfo };

        case userConstants.FORGOT_PASSWORD_SUCCESS:
            let forgotPasswordInfo = action.forgotPasswordSuccess;
            return { ...state, forgotPasswordInfo };

        case userConstants.LOGOUT_SUCCESS:
            return {};

        case userConstants.GETUSER_SUCCESS:
            let getUserSuccess = action.getUserSuccess;
            if (getUserSuccess.responseData) {
                return { ...state, getUserSuccess: getUserSuccess.responseData };
            }
            break;
        default:
            return state
    }
}
