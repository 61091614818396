
import React from "react"
import Carousel from 'react-elastic-carousel';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import BenefitModal from './../common/benefitmodal';
import PlanDetail from './../common/plandetail';
import CheckoutModal from './../common/checkoutmodal';
import PaymentModal from './../common/paymentmodal';
import MoreBenefitModal from './../common/morebenefitmodal';
import { logout } from "../../_actions/user.actions";
import { thisExpression } from "@babel/types";
import LogoutModal from "../common/logout";
import ShareWithModal from "../common/shareWith";
import { userService } from "../../_services";

class Plans extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            planId: 1,
            isSowModal: false, isPlanDetailModal: false,
            logoutPopup: false,
            shareWith: false,
            isCheckoutModal: false, isPaymentModal: false, isMoreBenefitModal: false,
            showImages: false,
            plan1: {
                planId: 1,
                title: "ENTERTAINERS",
                price: "100",
                discription: "commission free 3  months First 1000 girls Chance to win a spot in the First edition of BSWW MAGAZINE and a chance to win a Free Gucci bag($3,000 value)"
            },
            plan2: {
                planId: 2,
                title: "BOUTIQUES",
                price: "100",
                discription: "Zero platform fee for 3 months"
            },
            plan3: {
                planId: 3,
                title: "CLUB",
                price: "300",
                discription: "Top page featured club Advertising 3 months"
            },
            plan4: {
                planId: 4,
                title: "ENTERTAINERS",
                price: "1000",
                discription: " 9 months commission free and featured Entertainer 6 months top Page Advertising First edition of BSWW MAGAZINE and a chance to win a Free Gucci bag ($3,000 value) and $10,000 Plastic surgery giveaway "
            },
            plan5: {
                planId: 5,
                title: "ENTERTAINERS",
                price: "500",
                discription: "Commission free and featured Entertainer 6 months top Page Advertising First edition of BSWW MAGAZINE and a chance to win a Free Gucci bag($3,000 value) and $10, 000 Plastic surgery giveaway"
            },

            planDetails: {
                title: "",
                price: "",
                discription: ""
            },
        };
        this.breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2, itemsToScroll: 2 },
            { width: 850, itemsToShow: 3 },
            { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
            { width: 1450, itemsToShow: 4 },
            { width: 1750, itemsToShow: 5 },
        ]
    }



    openPlanBenefitModal = (ishow, plan, images) => {
        let planDetails = {}
        if (plan) {
            planDetails.title = plan.title
            planDetails.price = plan.price
            planDetails.discription = plan.discription
            this.setState({
                planDetails, showText: plan.planId
            });
        }
        if (ishow) {
            this.setState({
                shareWith: false, isSowModal: ishow, isPlanDetailModal: false, showImages: images, logoutPopup: false,
                isCheckoutModal: false, isPaymentModal: false, isMoreBenefitModal: false
            });
        }
    }
    logoutPopup = () => {
        this.setState({
            shareWith: false, logoutPopup: true, isPlanDetailModal: false, isSowModal: false,
            isCheckoutModal: false, isPaymentModal: false, isMoreBenefitModal: false
        })
    }
    shareWith = () => {
        this.setState({
            shareWith: true, logoutPopup: false, isPlanDetailModal: false, isSowModal: false,
            isCheckoutModal: false, isPaymentModal: false, isMoreBenefitModal: false
        })

    }
    openPlanDetailModal = (data, plan, images) => {
        let planDetails = {}
        if (plan) {
            planDetails.title = plan.title
            planDetails.price = plan.price
            planDetails.discription = plan.discription
            this.setState({
                planDetails
            });
        }
        this.setState({
            shareWith: false, isPlanDetailModal: data, isSowModal: false, logoutPopup: false,
            isCheckoutModal: false, isPaymentModal: false, isMoreBenefitModal: false
        });
    }
    openCheckoutModal = (data) => {
        this.setState({
            shareWith: false, isPlanDetailModal: false, isSowModal: false, logoutPopup: false,
            isCheckoutModal: data, isPaymentModal: false, isMoreBenefitModal: false
        });
    }
    openPaymentModal = (data, plan) => {

        let planDetails = {}
        if (plan) {
            planDetails.title = plan.title
            planDetails.price = plan.price
            planDetails.discription = plan.discription
            this.setState({
                planDetails
            });
        }
        this.setState({
            isPlanDetailModal: false, isSowModal: false, logoutPopup: false,
            isCheckoutModal: false, isPaymentModal: data, isMoreBenefitModal: false,

        });
    }
    openMoreBenefitModal = (data, plan) => {

        let planDetails = {}

        if (plan) {
            planDetails.title = plan.title
            planDetails.price = plan.price
            planDetails.discription = plan.discription
            this.setState({
                planDetails
            });
        }

        this.setState({
            isPlanDetailModal: false, isSowModal: false, logoutPopup: false,
            isCheckoutModal: false, isPaymentModal: false, isMoreBenefitModal: data,
        });
    }

    logout = () => {
        this.props.logout().then((res) => {
            this.props.history.push({ pathname: "/login" })
        })

    }

    componentDidMount() {
        this.getUserSubscribedPlan()
    }
    getUserSubscribedPlan = () => {
        this.setState({ email: " ", loader: true })
        userService.getUserSubscribedPlan().then((res) => {
            if (res.data.responseData && res.data.responseData.length > 0) {
                this.props.history.push({ pathname: "/profile" })
                this.setState({ loader: false })
            }
            this.setState({ loader: false })
        })
    }
    // function for redirect to profile page 
    profile = () => {
        this.props.history.push({ pathname: "/profile" })
    }
    render() {
        let { plan1, plan2, plan3, plan4, plan5, loader } = this.state
        if (loader) {
            return <div style={{ textAlign: "center", marginTop: "220px" }}>
                <img src={require("./../../assets/images/256x256.gif")} alt="logo" />
            </div>
        }
        return (

            <div className="authwrapper">
                <ShareWithModal showModal={this.state.shareWith}></ShareWithModal>
                <LogoutModal showModal={this.state.logoutPopup} logout={this.logout}></LogoutModal>
                <BenefitModal showModal={this.state.isSowModal} openCheckoutModal={this.openCheckoutModal} showImages={this.state.showImages} showText={this.state.showText} />
                <PlanDetail planDetailModal={this.state.isPlanDetailModal} openPlanBenefitModal={this.openPlanBenefitModal} planDetails={this.state.planDetails} />
                <CheckoutModal checkoutModal={this.state.isCheckoutModal} openCheckoutModal={this.openCheckoutModal} planDetails={this.state.planDetails} />
                <PaymentModal paymentModal={this.state.isPaymentModal} openCheckoutModal={this.openCheckoutModal} />
                <MoreBenefitModal moreBenefitModal={this.state.isMoreBenefitModal} openPlanBenefitModal={this.openPlanBenefitModal} />

                <span className="corner-art left">
                    <img src={require("./../../assets/images/left-bottom-shade.png")} alt="logo" />
                </span>
                <span className="corner-art right">
                    <img src={require("./../../assets/images/right-top-shade.png")} alt="logo" />
                </span>
                <div className="auth-container">
                    <div className="main-header">
                        <div className="main-logo-wrap">
                            <a className="authlogo" href="javascript:void(0);">
                                <img src={require("./../../assets/images/logo.png")} alt="logo" />
                            </a>
                        </div>
                        <div className="main-header-content">
                            <span className="main-title">
                                BSWW Pre-launch Specials
                        </span>
                            <span className="contact-info">
                                <span className="isd-code">219</span>
                                713-8698
                        </span>
                        </div>
                        <div className="main-header-nav">
                            <a className="header-nav-btn" style={{ cursor: "pointer", color: "white", borderBottom: "2px solid #fff", borderTop: "2px solid #fff", margin: "10px" }} onClick={this.shareWith}>
                                <i class="fa fa-share" aria-hidden="true"></i>
                                {/* <img src={require("./../../assets/images/profile-ico.png")} alt="logo" /> */}
                                &nbsp; Share
                            </a>
                            <a className="header-nav-btn" style={{ cursor: "pointer", color: "white", borderBottom: "2px solid #fff", borderTop: "2px solid #fff", margin: "10px" }} onClick={this.logoutPopup}>
                                {/* <img src={require("./../../assets/images/app-ico.png")} alt="logo" /> */}
                                &nbsp; Logout
                            </a>


                            {/* <a href="/" className="header-nav-btn">
                            <img src={require("./../../assets/images/search-ico.png")} alt="logo" />
                        </a> */}
                            {/* <a href="/" className="header-nav-btn menuBtn">
                            <span>MENU</span>
                        </a> */}
                        </div>
                    </div>
                    <div className="appContentWrap">
                        <Carousel enableAutoPlay autoPlaySpeed={5000} ref={ref => (this.carousel = ref)} focusOnSelect={false} breakPoints={this.breakPoints}>
                            <div className="carousal-item">
                                <div className="carousal-plan-wrap">
                                    <div className="carousal-plan-pic">
                                        <img src={require("./../../assets/images/screen3-2.jpg")} alt="plan-picture" />
                                    </div>
                                    <div className="carousal-plan-description-wrap">
                                        <div className="carousal-plan-name">
                                            <h3>{plan1.title}</h3>
                                        </div>
                                        <div className="carousal-plan-price">
                                            <p>${plan1.price}</p>
                                        </div>
                                        <div className="carousal-plan-desc text-1">
                                            <p>{plan1.discription}</p>
                                        </div>
                                        <div className="carousal-plan-action">
                                            <button type="button" className="btn theme-y" onClick={() => this.openPlanDetailModal(true, plan1)}>More Details</button>
                                            <button type="button" className="btn theme-b" onClick={() => this.openPlanBenefitModal(true, plan1)}>Subscribe now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousal-item">
                                <div className="carousal-plan-wrap plan-red">
                                    <div className="plan-red-top">
                                        <span>SPONSORED & TOP PAGE ADVERTISEING</span>
                                    </div>
                                    <div className="carousal-plan-pic">
                                        <img src={require("./../../assets/images/screen3-3.jpg")} alt="plan-picture" />
                                    </div>
                                    <div className="carousal-plan-description-wrap">
                                        <div className="carousal-plan-name">
                                            <h3>{plan2.title}</h3>
                                        </div>
                                        <div className="carousal-plan-price">
                                            <p>${plan2.price}</p>
                                        </div>
                                        <div className="carousal-plan-desc text-2">
                                            <p>{plan2.discription}</p>
                                        </div>
                                        <div className="carousal-plan-action">
                                            <button type="button" className="btn theme-y" onClick={() => this.openPlanBenefitModal(true, plan2, true)}>More Details</button>
                                            <button type="button" className="btn theme-b" onClick={() => this.openPlanBenefitModal(true, plan2)}>Subscribe now</button>
                                        </div>
                                    </div>
                                    <div className="plan-red-bottom"></div>
                                </div>
                            </div>
                            <div className="carousal-item">
                                <div className="carousal-plan-wrap">
                                    <div className="carousal-plan-pic">
                                        <img src={require("./../../assets/images/screen3-1.jpg")} alt="plan-picture" />
                                    </div>
                                    <div className="carousal-plan-description-wrap">
                                        <div className="carousal-plan-name">
                                            <h3>{plan3.title}</h3>
                                        </div>
                                        <div className="carousal-plan-price">
                                            <p>${plan3.price}</p>
                                        </div>
                                        <div className="carousal-plan-desc text-3">
                                            <p>{plan3.discription}</p>
                                        </div>
                                        <div className="carousal-plan-action">
                                            <button type="button" className="btn theme-y" onClick={() => this.openMoreBenefitModal(true, plan3)}>More Details</button>
                                            {/* <button type="button" className="btn theme-y" onClick={() => this.openPlanBenefitModal(true)}>More Details</button> */}
                                            <button type="button" className="btn theme-b" onClick={() => this.openPlanBenefitModal(true, plan3)}>Subscribe now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousal-item">
                                <div className="carousal-plan-wrap">
                                    <div className="carousal-plan-pic">
                                        <img src={require("./../../assets/images/screen3-4.jpg")} alt="plan-picture" />
                                    </div>
                                    <div className="carousal-plan-description-wrap">
                                        <div className="carousal-plan-name">
                                            <h3>{plan4.title}</h3>
                                        </div>
                                        <div className="carousal-plan-price">
                                            <p>${plan4.price}</p>
                                        </div>
                                        <div className="carousal-plan-desc text-4">
                                            <p>{plan4.discription}</p>
                                        </div>
                                        <div className="carousal-plan-action">
                                            <button type="button" className="btn theme-y" onClick={() => this.openPlanDetailModal(true, plan4)}>More Details</button>
                                            <button type="button" className="btn theme-b" onClick={() => this.openPlanBenefitModal(true, plan4)}>Subscribe now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousal-item">
                                <div className="carousal-plan-wrap">
                                    <div className="carousal-plan-pic">
                                        <img src={require("./../../assets/images/screen3-5.jpg")} alt="plan-picture" />
                                    </div>
                                    <div className="carousal-plan-description-wrap">
                                        <div className="carousal-plan-name">
                                            <h3>{plan5.title}</h3>
                                        </div>
                                        <div className="carousal-plan-price">
                                            <p>${plan5.price}</p>
                                        </div>
                                        <div className="carousal-plan-desc text-5">
                                            <p>{plan5.discription}</p>
                                        </div>
                                        <div className="carousal-plan-action">
                                            <button type="button" className="btn theme-y" onClick={() => this.openPlanDetailModal(true, plan5)}>More Details</button>
                                            <button type="button" className="btn theme-b" onClick={() => this.openPlanBenefitModal(true, plan5)}>Subscribe now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                        <div className="carousal-action-wrap">
                            <span className="carousal-action-btn left" onClick={() => this.carousel.slidePrev()}>PREV</span>
                            <span className="carousal-action-btn right" onClick={() => this.carousel.slideNext()}>NEXT</span>
                        </div>
                        <div>
                            <p style={{ color: "white" }}>Baddest Strippers Worldwide is a mobile app and website that will change the way that a club's, dancers and boutiques interact with the customers. The customers can receive specials from the club.<br></br>
                                The app will feature some of the most prominent aspects of several social media sites combining them in a way that will prove to be a unique and user friendly experience. There is also an in-app currency which will be a proprietary new way for the users to interact and conduct transactions as well as redeem and claim specials related to the industry.</p>
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = state => {
    return {};
};
export default withRouter(connect(mapStateToProps, { logout })(Plans));